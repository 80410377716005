import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [theme, setTheme] = useState("theme-dark");

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "theme-dark";
    setTheme(savedTheme);
    document.body.className = savedTheme;
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "theme-dark" ? "theme-light" : "theme-dark";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.body.className = newTheme;
  };

  return (
    <div className={`App ${theme}`}>
      <div className='h-full bg-bgColor flex justify-center items-center'>
        <div className='w-96 bg-cardBg h-96 p-4 border-borderColor border-2'>

          <div className='bg-bgSuccess'>
            <p className='text-success p-4 text-center'>InBook</p>
          </div>

          <button
            type="button"
            onClick={toggleTheme}
            class="rounded mt-20 bg-buttonBg px-6 pb-2 pt-2.5 text-sm font-medium uppercase transition duration-4000 hover:bg-second text-buttonText">
            Change Theme
          </button>

          <p className='text-textColor'>hello world</p>
        </div>
      </div>
    </div>
  );
}

export default App;
